import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';

const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_BUCKET_ENDPOINT,
  forcePathStyle: false,
  region: process.env.REACT_APP_BUCKET_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_KEY
  }
});

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
        localeOptions.filter(
          (x) => x.id === localStorage.getItem('currentLanguage')
        ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('cb_current_user') !== null && localStorage.getItem('cb_current_user') !== 'undefined'
        ? JSON.parse(localStorage.getItem('cb_current_user'))
        : null;
  } catch (error) {
    console.log(error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  const allPermission = [];
  try {
    if (user) {
      if (user.permissions) {
        user.permissions.forEach(permission => {
          allPermission.push(permission.key);
        });
      }
      const updatedUser = user;
      updatedUser.permissions = allPermission;
      updatedUser.frontend_logged_in_time = (new Date());
      localStorage.setItem('cb_current_user', JSON.stringify(updatedUser));
    } else {
      localStorage.removeItem('cb_current_user');
      localStorage.setItem('cb_current_user', JSON.stringify(user));
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const hasPrevilleges = (selectedPermissions) => {
  const currentUser = getCurrentUser();
  let allPermissions = [];
  let status = false;

  if (currentUser) {
    if (currentUser.role) {
      if (currentUser.role === "ROOT_ADMIN") {
        return true;
      }
    }
    allPermissions = currentUser.permissions;
    if (selectedPermissions.length > 0) {
      if (Array.isArray(selectedPermissions)) {
        selectedPermissions.forEach((res) => {
          if (allPermissions && allPermissions.includes(res)) {
            status = true;
          }
        });
      } else if (allPermissions && allPermissions.includes(selectedPermissions)) {
        status = true;
      }
      return status;
    }
  }
  return false;
};

export const currencyFormat = (num, precision) => {
  if (Number.isNaN(num)) {
    return num;
  }
  return Number(num).toFixed(precision).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
};

export const getUrlParameter = function (name) {
  const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.href);
  return results !== null ? results[1] : 0;
};

export const prepareFileNames = (files, folder = '') => {
  const updatedFiles = {
    names: [],
    files: []
  };
  files.forEach((file) => {
    const updatedFile = file;
    const user = getCurrentUser();
    const path = folder !== '' ? `${user.organisation_id}/${folder}${Date.now()}-${file.name}` : `${user.organisation_id}/${Date.now()}-${file.name}`;
    updatedFile.updated_path = path;
    updatedFiles.files.push(updatedFile);
    updatedFiles.names.push(path);
  });

  return updatedFiles;
}

export const uploadFileToBucket = async (file) => {
  console.log('---------=========-------');
  if (file.updated_path) {
    const args = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.updated_path,
      Body: file,
      ACL: "public-read",
    };
    const data = await s3Client.send(new PutObjectCommand(args));
    return data;
  }
  
  console.log('----------------');

  return false
}